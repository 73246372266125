import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import React, { useCallback } from 'react';
import TextInput from '@pages/email-notification-widget/input-fields/text-input';
import apiClient from '@api/api.client';
import emailValidator from 'email-validator';

export default function LoginEmailNotification() {
    const navigate = useNavigate();
    const location = useLocation();
    const [email, setEmail] = React.useState<string | null>(null);
    const [success, setSuccess] = React.useState<boolean | undefined>(
        undefined,
    );

    const login = useCallback(() => {
        if (email) {
            apiClient
                .loginEmailNotification(email)
                .then(() => {
                    setSuccess(true);
                })
                .catch(() => {
                    setSuccess(false);
                });
        }
    }, [email]);

    const showSuccess = success === true;
    const showError = success === false;

    return (
        <>
            <p>
                Bei den kostenlosen E-Mail-Alerts der Börsen-Zeitung können Sie
                bis zu zehn Suchbegriffe eingeben und erhalten morgens um 6:00
                Uhr eine E-Mail mit den aktuellen Artikeln der Börsen-Zeitung zu
                diesen Themen.
            </p>
            <p className="mb-4">
                Hier können Sie E-Mail-Alerts erstellen und verwalten. Bitte
                geben Sie dazu Ihre E-Mail-Adresse ein oder{' '}
                <button
                    onClick={() =>
                        navigate(
                            `/email-notification/register${location.search}`,
                        )
                    }
                    className="underline"
                >
                    registrieren Sie sich.
                </button>{' '}
                Wir senden eine E-Mail mit einem Link, um Ihre E-Mail-Adresse zu
                bestätigen.
            </p>
            {showSuccess && (
                <p className="mb-4 text-positive-color">
                    E-Mail erfolgreich versendet.
                </p>
            )}
            {showError && (
                <p className="mb-4 text-negative-color">
                    E-Mail konnte nicht versendet werden.
                </p>
            )}
            <div className="flex justify-between">
                <TextInput
                    type="email"
                    placeholder="E-Mail-Adresse"
                    value={email ?? ''}
                    setValue={setEmail}
                    className="grow"
                    testId={'email-input-login'}
                />
                <button
                    onClick={login}
                    disabled={!email || !emailValidator.validate(email)}
                    className="bg-primary-color disabled:bg-secondary-color !text-button-text-color px-6 hover:cursor-pointer"
                    data-testid={'login-email-notification-button'}
                >
                    Fortfahren
                </button>
            </div>
        </>
    );
}
